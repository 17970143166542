var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "alert-dialog",
      attrs: { visible: _vm.dialogFormVisible },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "alarm-dialog-delete",
          attrs: { slot: "title" },
          slot: "title",
        },
        [_vm._v(" Вы действительно хотите удалить таймслот? ")]
      ),
      _c("div", [
        _c("h1", { staticClass: "alert-title" }, [_vm._v("⚠️ ВНИМАНИЕ! ⚠️")]),
        _c("div", { staticClass: "alert-text" }, [
          _c("div", { staticClass: "alert-delete-item" }, [
            _vm._v(" Eсли вы удалите таймслот, машина "),
            _c("span", { staticStyle: { color: "#f56c6c" } }, [
              _c("b", [_vm._v(_vm._s(_vm.dialogData["plate_truck"]))]),
            ]),
            _vm._v(" не сможет приехать по нему. "),
          ]),
          _c("div", { staticClass: "alert-delete-item" }, [
            _c("b", [_vm._v(" Дата:")]),
            _c("span", { staticStyle: { color: "#f56c6c" } }, [
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(_vm.dateFormat.date) +
                    "  " +
                    _vm._s(_vm.dateFormat.from) +
                    " - " +
                    _vm._s(_vm.dateFormat.to) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "alert-delete-item" }, [
            _c("b", [_vm._v(" Терминал:")]),
            _c("span", { staticStyle: { color: "#f56c6c" } }, [
              _c("b", [_vm._v(" " + _vm._s(_vm.dialogData["unload_name"]))]),
            ]),
          ]),
        ]),
      ]),
      _c("el-checkbox", {
        staticClass: "alert-dialog__checkbox",
        attrs: { label: "Подтверждаю условия удаления тайм слота", border: "" },
        model: {
          value: _vm.confirmDelete,
          callback: function ($$v) {
            _vm.confirmDelete = $$v
          },
          expression: "confirmDelete",
        },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              staticClass: "alert-button",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v(" ОТМЕНИТЬ ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "alert-button",
              attrs: { disabled: !_vm.confirmDelete, type: "danger" },
              on: { click: _vm.submit },
            },
            [_vm._v(" УДАЛИТЬ ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }